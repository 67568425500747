<template>
  <div class="information">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/activityList'">活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content" v-if="Object.keys(info).length > 0">
      <h3 class="title-t">活动内容</h3>
      <div class="box mg-t-20">
        <div class="flex mg-t-20 align-top">
          <p class="mg-r-20 label">封面图</p>
          <el-image :src="info.cover_image"></el-image>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">活动标题</p>
          <div>
            <p>{{ info.name }}</p>
          </div>
        </div>
        <div class="flex mg-t-20 align-top">
          <p class="mg-r-20 label">内容</p>
          <div class="desc" v-html="info.content"></div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">主办方</p>
          <div>
            <p>{{ info.sponsor }}</p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">活动名额</p>
          <div>
            <p>{{ info.number }}</p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">活动地址</p>
          <div>
            <p>
              {{ info.province.area_name }}{{ info.city.area_name
              }}{{ info.area.area_name }}{{ info.area.address }}
            </p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">活动分类</p>
          <div>
            <p>{{ info.category_uuid }}</p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">活动开始时间</p>
          <div>
            <p>{{ info.start_time }}</p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">活动结束时间</p>
          <div>
            <p>{{ info.end_time }}</p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">报名开始时间</p>
          <div>
            <p>{{ info.apply_start_time }}</p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">报名结束时间</p>
          <div>
            <p>{{ info.apply_end_time }}</p>
          </div>
        </div>
        <div class="flex">
          <p class="mg-r-20 label">上架时间</p>
          <div>
            <p v-if="info.shelf_type == 1">立即上架</p>
            <p v-else>{{ info.shelf_time }}</p>
          </div>
        </div>
        '

        <div class="flex mg-t-20">
          <p class="mg-r-20 label">状态</p>
          <div>
            <p>{{ info.status == 1 ? "显示" : "隐藏" }}</p>
          </div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">是否置顶</p>
          <div>
            <p>{{ info.is_top == 1 ? "是" : "否" }}</p>
          </div>
        </div>
        <div class="flex mg-t-20">
          <p class="mg-r-20 label">是否热门</p>
          <div>
            <p>{{ info.is_hot == 1 ? "是" : "否" }}</p>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
    </div>

    <div class="operateBtn">
      <el-button
        type="primary"
        v-if="info.is_hot == 2"
        size="mini"
        @click="setHot"
        >设为热门</el-button
      >
      <el-button
        type="danger"
        v-if="info.is_hot == 1"
        size="mini"
        @click="setHot"
        >取消热门</el-button
      >

      <el-button size="mini" @click="$router.push(`/activityData?uuid=${$route.query.uuid}`)">数据</el-button>
      <el-button size="mini" v-if="info.is_top == 2" @click="setTop"
        >置顶</el-button
      >
      <el-button size="mini" v-if="info.is_top == 1" @click="setTop"
        >取消置顶</el-button
      >
      <el-button size="mini" v-if="info.status == 2" @click="isShow"
        >显示</el-button
      >
      <el-button size="mini" v-if="info.status == 1" @click="isShow"
        >隐藏</el-button
      >
      <el-button
        size="mini"
        @click="$router.push(`/addNewActivity?uuid=${$route.query.uuid}`)"
        >编辑</el-button
      >

      <el-button size="mini" @click="delInfomation">删除</el-button>
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "information",
  data() {
    return {
      uuid: "",
      info: {},
      tableData: [],
    };
  },
  created() {
    this.uuid = this.$route.query.uuid;
    this.getInfo();
  },
  methods: {
    // 热门
    setHot() {
      let isHot = this.info.is_hot == 1 ? 2 : 1;
      this.info.is_hot = isHot;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          is_hot: isHot,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Information/hot/${this.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 置顶
    setTop() {
      let isTop = this.info.is_top == 1 ? 2 : 1;
      this.info.is_top = isTop;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          is_top: isTop,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Information/top/${this.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 显示隐藏
    isShow() {
      let status = this.info.status == 1 ? 2 : 1;
      this.info.status = status;
      this.axios({
        method: "put", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        data: {
          status: status,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Information/status/${this.uuid}`,
      })
        .then((res) => {
          this.$message.success("操作成功！");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    // 获取详情
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Activity/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          this.info = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 删除
    delInfomation() {
      this.$confirm("删除后无法恢复，确定删除吗？", "删除确认", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          this.axios({
            method: "delete", //请求方法
            headers: {
              "Content-Type": "application/json;",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            url: ` https://time.api.boyalife.cn/v1/cms/Activity/${this.uuid}`,
          })
            .then(() => {
              this.$message.success("删除成功！");
              this.$router.go(-1);
            })
            .catch((err) => {
              this.$message.error(err.response.data.error.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
.information {
  margin-bottom: 80px;
}
.information .content,
.recommondGoods {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}
.information .content .box {
  margin-left: 20px;
}
.information .content .flex {
  align-items: center;
  margin: 10px 0;
}
.information .content .label {
  width: 100px;
  text-align: right;
  color: #666;
}
.information .content .el-image {
  max-width: 200px;
  margin: 20px 0;
}
.information .content .box .el-image.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.recommondGoods .el-table .el-image {
  width: 100px;
  height: auto;
  border-radius: 0;
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
  max-height: 50px;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}
.desc {
  width: 80%;
}
.desc >>> img {
  max-width: 50%;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
