<template>
  <div class="addNewActivity">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>营销</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/activityList'">活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageType }}活动</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="stepList boxLayout">
      <el-steps
        :space="'50%'"
        :active="active"
        finish-status="success"
        align-center
      >
        <el-step title="编辑活动基本信息"></el-step>
        <el-step title="编辑活动详情"></el-step>
      </el-steps>
    </div>
    <div class="info boxLayout mg-t-20" v-if="active === 0">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        class="mg-b-50"
        :model="basicInfoForm"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="封面图片" prop="cover_image">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.cover_image != ''"
              :src="basicInfoForm.cover_image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanners(true)">从素材库选择</el-button>
          <p class="font-12 color-999">
            建议尺寸：690*276像素，拖拽图片可以调整顺序，最多上传15张
          </p>
        </el-form-item>
        <el-form-item label="活动banner">
          <uploadImagesBtn @getImageUrl="getImageUrl" />
          <el-button  type="text"  @click="chooseBanners(false)">从素材库选择</el-button>
          <div
            class="cover_image_list flex"
            v-if="basicInfoForm.banner_image.length > 0"
          >
            <div
              class="img"
              v-for="(item, index) in basicInfoForm.banner_image"
              :key="index"
            >
              <i class="el-icon-delete" @click.stop="deleteImg(index)"></i>
              <el-image :src="item" fit="cover"></el-image>
            </div>
          </div>
          <p class="font-12 color-999">
            建议尺寸：390*150像素，拖拽图片可以调整顺序，最多上传15张
          </p>
        </el-form-item>
        <el-form-item label="主办方" prop="sponsor">
          <el-input v-model="basicInfoForm.sponsor"></el-input>
        </el-form-item>
        <el-form-item label="活动标题" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="活动名额" prop="number">
          <el-input v-model="basicInfoForm.number"></el-input>
        </el-form-item>
        <el-form-item label="活动地址" prop="address">
          <p class="address" @click="selectMapControl">
            <span class="font-12 color-999" v-if="basicInfoForm.address == ''"
              >点击启用地图控件</span
            >
            <span v-else
              >{{ basicInfoForm.address
              }}<span class="font-12 color-999 mg-l-20"
                >(点击可修改)</span
              ></span
            >
          </p>
        </el-form-item>
        <el-form-item label="精简地址" prop="short_address">
          <el-input v-model="basicInfoForm.short_address"></el-input>
        </el-form-item>
        <el-form-item label="活动分类" prop="activity_category">
          <el-select
            v-model="basicInfoForm.activity_category"
            placeholder="请选择"
          >
            <el-option
              v-for="group in options"
              :key="group.uuid"
              :label="group.category_name"
              :value="group.uuid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动开始" prop="start_time">
          <el-date-picker
            v-model="basicInfoForm.start_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动结束" prop="end_time">
          <el-date-picker
            v-model="basicInfoForm.end_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="报名开始" prop="apply_start_time">
          <el-date-picker
            v-model="basicInfoForm.apply_start_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="报名结束" prop="apply_end_time">
          <el-date-picker
            v-model="basicInfoForm.apply_end_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动内容（推送）" prop="content_push">
          <el-input v-model="basicInfoForm.content_push"></el-input>
        </el-form-item>
        <el-form-item
          label="上架时间"
          :prop="basicInfoForm.shelf_type == 2 ? 'shelf_time' : ''"
        >
          <el-radio v-model="basicInfoForm.shelf_type" :label="1"
            >立即上架</el-radio
          ><br />
          <el-radio v-model="basicInfoForm.shelf_type" :label="2"
            >定时上架</el-radio
          >
          <el-date-picker
            v-if="basicInfoForm.shelf_type == 2"
            v-model="basicInfoForm.shelf_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <h3 class="title-t mg-t-50 mg-b-30">报名信息</h3>
      <el-button class="mg-b-30" type="text" @click="addNewInformation"
        >+ 添加信息项</el-button
      >
      <el-table
        v-if="basicInfoForm.apply_setting.length"
        :data="basicInfoForm.apply_setting"
        border
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" :align="'center'" width="50">
        </el-table-column>
        <el-table-column prop="name" :align="'center'" label="标题">
        </el-table-column>
        <el-table-column prop="title" :align="'center'" label="题型">
        </el-table-column>
        <el-table-column prop="tip" :align="'center'" label="提示输入">
        </el-table-column>
        <el-table-column label="操作" :align="'center'" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="editInformation(scope.row, scope.$index)"
              >编辑</el-button
            >
            <el-button
              type="text"
              @click="delInformation(scope.$index, basicInfoForm.apply_setting)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="info boxLayout mg-t-20" v-if="active === 1">
      <h3 class="title-t mg-b-30">活动详情</h3>
      <wangEditorTool ref="editors" :content="basicInfoForm.content" />
    </div>
    <div class="operateBtn">
      <el-button
        type="primary"
        size="mini"
        v-if="active === 1"
        @click="active--"
        >上一步</el-button
      >
      <el-button type="primary" v-if="active === 0" size="mini" @click="toNext"
        >下一步</el-button
      >
      <el-button size="mini" @click="$router.go(-1)" v-if="active === 0"
        >返回</el-button
      >
      <el-button size="mini" v-if="active === 1" @click="submit('form')"
        >保存</el-button
      >
    </div>
    <el-dialog
      :title="title"
      :width="'760px'"
      :visible.sync="dialogFormVisible"
    >
      <el-form
        :model="dialogForm"
        label-width="100px"
        ref="dialogForm"
        :rules="dialogRules"
      >
        <el-form-item label="题型" prop="type">
          <el-radio-group
            v-model="dialogForm.type"
            size="medium"
            @input="selectType"
          >
            <el-radio-button :label="1">单选</el-radio-button>
            <el-radio-button :label="2">多选</el-radio-button>
            <el-radio-button :label="3">日期</el-radio-button>
            <el-radio-button :label="4">图片</el-radio-button>
            <el-radio-button :label="5">地区</el-radio-button>
            <el-radio-button :label="6">数字</el-radio-button>
            <el-radio-button :label="7">文本</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="信息标题" prop="name">
          <el-input v-model="dialogForm.name"></el-input>
        </el-form-item>
        <el-form-item label="提示输入" prop="tip">
          <el-input v-model="dialogForm.tip"></el-input>
        </el-form-item>
        <el-form-item
          label="选项"
          v-if="dialogForm.type == 1 || dialogForm.type === 2"
        >
          <div class="chooseBox" v-if="dialogForm.options.length > 0">
            <p
              class="flex align-center mg-b-10"
              v-for="(item, index) in dialogForm.options"
              :key="index"
            >
              <span>{{ chooseIndex[index] }}</span>
              <el-input class="mg-l-20 mg-r-20" v-model="item.name"></el-input>
              <el-button type="text" @click="delChoose(index)">删除</el-button>
            </p>
          </div>
          <el-button type="text" @click="addChoose"> + 添加选项</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDialogForm()">确 定</el-button>
      </div>
    </el-dialog>
    <div class="boxLayout layouts" :class="{ toTop: dialogVisible }">
      <div class="bj"></div>
      <div class="box">
        <h3 class="title-t flex space-between">
          <p>选择地址</p>
          <el-button class="mg-l-10" type="text" @click="clearForm"
            >清空查询参数</el-button
          >
        </h3>
        <el-form :model="form" ref="mapForm">
          <div class="flex mg-b-10 align-center">
            <h4
              class="mg-r-10 text-right"
              style="width: 100px; margin-right: 10px"
            >
              省市区查询
            </h4>
            <div class="input-item mg-r-10">
              <!-- <div class="input-item-prepend"><span class="input-item-text">省市区</span></div> -->
              <el-select
                value-key="area_id"
                clearable
                v-model="form.provinceValue"
                placeholder="请选择"
                @change="search($event)"
                @clear="clearProvince"
              >
                <el-option
                  placeholder="请选择"
                  clearable
                  v-for="item in form.provinceoptions"
                  :key="item.area_id"
                  :label="item.area_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="input-item mg-r-10">
              <el-select
                value-key="area_id"
                clearable
                v-model="form.cityValue"
                placeholder="请选择"
                @change="search($event)"
                @clear="clearCity"
              >
                <el-option
                  placeholder="请选择"
                  clearable
                  v-for="item in form.cityOptions"
                  :key="item.area_id"
                  :label="item.area_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="input-item mg-r-10">
              <el-select
                value-key="area_id"
                clearable
                v-model="form.districtValue"
                placeholder="请选择"
                @change="search($event)"
                @clear="clearDis"
              >
                <el-option
                  placeholder="请选择"
                  clearable
                  v-for="item in form.districtOptions"
                  :key="item.area_id"
                  :label="item.area_name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <p
              class="color-999 font-12"
              style="width: 200px"
              v-if="Object.keys(this.form.districtValue).length <= 0"
            >
              请先选择省市区
            </p>
          </div>
          <div
            class="flex align-center"
            v-if="Object.keys(this.form.districtValue).length > 0"
          >
            <p style="width: 100px; margin-right: 10px" class="text-right">
              详细地址查询
            </p>
            <el-input
              v-model="form.keyword"
              clearable
              class="ipt"
              placeholder="请输入详细地址"
              @input="kewordSearch"
            ></el-input>
          </div>
          <!-- <p style="width: 100px;margin-right: 10px; text-right" class="text-right">经纬度查询</p> -->
          <el-form-item
            label="经纬度"
            prop="latitude"
            label-width="110px"
            v-if="Object.keys(this.form.districtValue).length > 0"
          >
            <div class="flex align-center mg-t-10">
              <el-input
                v-model="form.longitude"
                clearable
                class="mg-r-10"
                placeholder="请输入经度"
                @input="numSearch"
              ></el-input>
              <el-input
                v-model="form.latitude"
                clearable
                placeholder="请输入纬度"
                @input="numSearch"
              ></el-input>
            </div>
          </el-form-item>
          <div
            class="flex align-center mg-t-10"
            v-if="Object.keys(this.form.districtValue).length > 0"
          >
            <p
              style="width: 100px;margin-right: 10px; text-right"
              class="text-right"
            >
              地址:
            </p>
            <p>{{ form.address !== "" ? form.address : "暂无" }}</p>
          </div>
        </el-form>
        <div class="map mg-t-10">
          <div
            class="ly"
            v-show="Object.keys(this.form.districtValue).length == 0"
          ></div>
          <div id="container"></div>
          <div id="panel"></div>
        </div>
        <div class="btns text-center mg-t-20 mg-b-20">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="confirmDialog('mapForm')"
            >确定</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      title="选择图片"
      :visible.sync="imgDialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @selectImg="selectImg" />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogImageVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
      :isSingle="isSingle" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
   
    </el-dialog>
  </div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import wangEditorTool from "../../../components/wangEditorTool.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import uploadImagesBtn from "../../../components/uploadImagesBtn.vue";
import imageDialog from "../../../components/imageDialog.vue";
window._AMapSecurityConfig = {
  // 安全密钥
  securityJsCode: "5cc2994d6e5654e9bd6fbc0c50c5af86",
};
export default {
  name: "addNewActivity",
  components: { Editor, Toolbar, MaterialLibrary, wangEditorTool ,uploadImagesBtn,imageDialog},
  data() {
    return {
      isSingle: false,
      dialogImageVisible: false,
      title: "添加信息项",
      pageType: "",
      imgType: "",
      chooseIndex: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "J",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      active: 0,
      dialogForm: {
        type: "单选",
        name: "",
        tip: "",
        options: [],
      },
      uploadImgType: "",
      imgDialogVisible: false,
      dialogVisible: false,
      isShow: "",
      //地图
      placeSearch: "",
      districtSearch: "",
      form: {
        address: "",
        longitude: "",
        latitude: "",
        visible: false,
        provinceValue: {},
        provinceoptions: [],
        cityValue: {},
        cityOptions: [],
        districtValue: {},
        districtOptions: [],
        keyword: "",
        searchWord: "",
      },
      dialogFormVisible: false,
      basicInfoForm: {
        name: "",
        sponsor: "",
        province: "",
        city: "",
        area: "",
        address: "",
        short_address: '',
        number: "",
        start_time: "",
        end_time: "",
        apply_start_time: "",
        apply_end_time: "",
        shelf_type: 1,
        shelf_time: "",
        cover_image: "",
        banner_image: [],
        content: "",
        activity_category: "",
        apply_setting: [],
        content_push: "",
      },
      editIndex: "",
      options: [],
      rules: {
        cover_image: { required: true, message: "请选择", trigger: "change" },
        name: { required: true, message: "请输入", trigger: "input" },
        number: { required: true, message: "请输入", trigger: "input" },
        address: { required: true, message: "请选择", trigger: "change" },
        start_time: { required: true, message: "请选择", trigger: "change" },
        end_time: { required: true, message: "请选择", trigger: "change" },
        apply_start_time: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        apply_end_time: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        activity_category: {
          required: true,
          message: "请选择",
          trigger: "change",
        },
        shelf_time: { required: true, message: "请选择", trigger: "change" },
        sponsor: { required: true, message: "请输入", trigger: "input" },
        content_push: { required: true, message: "请输入", trigger: "input" },
      },
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default",
      tableData: [],
      dialogRules: {
        type: [{ required: true, message: "请选择", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "input" }],
        tip: [{ required: true, message: "请输入", trigger: "input" }],
      },
    };
  },
  created() {
    this.getActivityCategoryList();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
    this.initMap();
  },
  methods: {
    httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.cover_image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    chooseBanners (v) {
      this.isSingle = v
      this.dialogImageVisible = true
    },
    closeImageDialog () {
      this.dialogImageVisible = false
    },
    getImageUrl (item) {
      this.basicInfoForm.banner_image.push(item)
    },
    confirmImageDialog (item) {
      if (!this.isSingle) {
        item.map(i => {
          this.basicInfoForm.banner_image.push(i.url)
        })
      } else {
        this.basicInfoForm.cover_image = item[0].url
      }
     
      this.dialogImageVisible = false

    },
    // 获取详情
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Activity/${this.$route.query.uuid}`,
      })
        .then((res) => {
          console.log(res);
          let params = res.data.result;
          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          if (this.basicInfoForm.banner_image == null)
            this.basicInfoForm.banner_image = [];
          this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);
          if (this.basicInfoForm.address == null) {
            this.basicInfoForm.address =
              this.basicInfoForm.province.area_name +
              this.basicInfoForm.city.area_name +
              this.basicInfoForm.area.area_name;
          }
          this.basicInfoForm.setting.map((item) => {
            item.title = "";
            if (item.type == 1) {
              item.title = "单选";
            } else if (item.type == 2) {
              item.title = "多选";
            } else if (item.type == 3) {
              item.title = "日期";
            } else if (item.type == 4) {
              item.title = "图片";
            } else if (item.type == 5) {
              item.title = "地区";
            } else if (item.type == 6) {
              item.title = "数字";
            } else if (item.type == 7) {
              item.title = "文本";
            }
          });
          this.basicInfoForm.apply_setting = this.basicInfoForm.setting;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },

    // 删除图片
    deleteImg(index) {
      this.basicInfoForm.banner_image.splice(index, 1);
    },
    selectType(v) {
      console.log(v);
      switch (v) {
        case 1:
          this.dialogForm.title = "单选";
          break;
        case 2:
          this.dialogForm.title = "多选";
          break;
        case 3:
          this.dialogForm.title = "日期";
          break;
        case 4:
          this.dialogForm.title = "图片";
          break;
        case 5:
          this.dialogForm.title = "地区";
          break;
        case 6:
          this.dialogForm.title = "数字";
          break;
        case 7:
          this.dialogForm.title = "文本";
          break;
      }
    },
    // 选择banner
    chooseBanner(type) {
      this.uploadImgType = type;
      this.imgType = "chooseThumbnail_image";
      this.imgDialogVisible = true;
    },
    // 选择封面
    selectImg(item) {
      if (this.uploadImgType == "cover_image") {
        this.basicInfoForm.cover_image = item.url;
      } else {
        this.basicInfoForm.banner_image.push(item.url);
      }
      this.imgDialogVisible = false;
    },
    addNewInformation() {
      this.title = "添加信息项";
      this.dialogFormVisible = true;
      this.dialogForm = {
        type: 1,
        title: "单选",
        tip: "",
        options: [],
      };
    },
    editInformation(item, index) {
      this.title = "编辑信息项";
      this.dialogFormVisible = true;
      this.dialogForm = item;
      this.editIndex = index;
    },
    delInformation(index, rows) {
      rows.splice(index, 1);
    },
    addChoose() {
      let obj = {
        name: "",
      };
      this.dialogForm.options.push(obj);
    },
    // 删除选项
    delChoose(i) {
      this.dialogForm.options.splice(i, 1);
    },
    // 提交信息项
    confirmDialogForm(form) {
      if (this.dialogForm.type === "单选" || this.dialogForm.type === "多选") {
        if (
          this.dialogForm.options.length > 0 &&
          this.dialogForm.options[0].name !== ""
        ) {
          this.validation();
        } else {
          this.$message.error("请至少添加且填写一条选项");
          return false;
        }
      } else {
        this.validation();
      }
    },
    validation() {
      this.$refs["dialogForm"].validate((valid) => {
        if (valid) {
          if (this.title == "添加信息项") {
            console.log(1);
            this.basicInfoForm.apply_setting.push(this.dialogForm);
          } else {
            console.log(2);

            this.basicInfoForm.apply_setting[this.editIndex] = this.dialogForm;
          }
          this.dialogFormVisible = false;
        } else {
          return false;
        }
      });
    },
    toNext() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.active++;
        }
      });
    },
    submit() {
      this.basicInfoForm.content = this.$refs.editors.html;
      if (this.basicInfoForm.shelf_type == 1) {
        delete this.basicInfoForm.shelf_time;
      }
      this.basicInfoForm.apply_setting.map((item) => {
        delete item.title;
      });
      if (Object.keys(this.$route.query).length !== 0) {
        this.axios({
          method: "put", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: this.basicInfoForm,
          url: ` https://time.api.boyalife.cn/v1/cms/Activity/${this.basicInfoForm.uuid}`,
        })
          .then((res) => {
            this.$message.success("编辑成功！");
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$message.success(err);
          });
      } else {
        console.log(this.basicInfoForm);

        this.axios({
          method: "post", //请求方法
          headers: {
            "Content-Type": "application/json",
            "X-Access-Token": this.storage.get("__token"),
            Authorization: `Bearer ${this.storage.get("__token")}`,
          },
          data: { ...this.basicInfoForm },
          url: ` https://time.api.boyalife.cn/v1/cms/Activity`,
        })
          .then((res) => {
            this.$message.success("新增成功！");
            this.$router.go(-1);
          })
          .catch((err) => {
            this.$message.success(err);
          });
      }
    },
    // 活动分类
    getActivityCategoryList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/ActivityCategory`,
      })
        .then((res) => {
          this.options = res.data.result.data;
          console.log(this.options);
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    selectMapControl() {
      this.getProvince(-1);
      if (this.pageType == "编辑") {
        for (let i in this.basicInfoForm) {
          this.form[i] = JSON.parse(JSON.stringify(this.basicInfoForm[i]));
        }
        console.log(this.form);
        this.form.province !== ""
          ? (this.form.provinceValue = this.form.province)
          : "";
        this.form.city !== "" ? (this.form.cityValue = this.form.city) : "";
        this.form.area !== "" ? (this.form.districtValue = this.form.area) : "";
        this.getProvince(-1);
        this.getCity(this.form.province_uuid);
        this.getArea(this.form.city_uuid);
        this.placeSearch.search(this.form.address, function (status, result) {
          // this.form.latitude = result.poiList.
        });
      } else {
        this.getProvince("-1");
      }
      this.dialogVisible = true;
    },
    getPcd(address) {
      // 获取省
      let provinceIndex = address.lastIndexOf("省");
      let provinceStr = address.substring(0, provinceIndex);
    },
    // 确认选择地址
    confirmDialog(form) {
      if (this.form.districtValue == "") {
        this.$message.error("省市区必选");
        return false;
      }
      if (
        this.form.latitude !== "" &&
        this.form.longitude !== "" &&
        this.form.address !== ""
      ) {
        this.dialogVisible = false;
        for (let i in this.form) {
          this.basicInfoForm[i] = JSON.parse(JSON.stringify(this.form[i]));
        }
        this.basicInfoForm.province_uuid =
          this.basicInfoForm.provinceValue.area_id + "";
        this.basicInfoForm.city_uuid =
          this.basicInfoForm.cityValue.area_id + "";
        this.basicInfoForm.area_uuid =
          this.basicInfoForm.districtValue.area_id + "";
        delete this.basicInfoForm.provinceoptions;
        delete this.basicInfoForm.cityOptions;
        delete this.basicInfoForm.districtOptions;
        delete this.basicInfoForm.keyword;
        delete this.basicInfoForm.provinceValue;
        delete this.basicInfoForm.cityValue;
        delete this.basicInfoForm.districtValue;
      } else {
        this.$message.error("请选择地图或者手动输入经纬度");
      }
    },
    clearProvince() {
      this.form.provinceValue = "";
      this.form.cityValue = "";
      this.form.districtValue = "";
    },
    clearCity() {
      this.form.cityValue = "";
      this.form.districtValue = "";
    },
    clearDis() {
      this.form.districtValue = "";
    },
    initMap() {
      let that = this;
      AMapLoader.load({
        key: "	a60d52419a53d04eb46d0248b5ee5f14", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.DistrictSearch",
          "AMap.PlaceSearch",
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 10, //初始化地图级别
            center: [116.395672, 39.906042], //初始化地图中心点位置
          });
          //点击获取经纬度;
          this.map.on("click", (e) => {
            // 获取经纬度
            this.form.longitude = e.lnglat.lng;
            this.form.latitude = e.lnglat.lat;
            // 清除点
            this.removeMarker();
            // 标记点
            this.setMapMarker();
            this.toGeoCoder();
            this.getPcd();
          });
          this.geoCoder = new AMap.Geocoder({
            radius: 1000, //范围，默认：500
          });
          // 关键字搜索
          this.placeSearch = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            city: "010", // 兴趣点城市
            citylimit: false, //是否强制限制在设置的城市内搜索
            map: this.map, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });

          AMap.Event.addListener(
            this.placeSearch,
            "selectChanged",
            function (data) {
              that.form.latitude = data.selected.data.location.lat;
              that.form.longitude = data.selected.data.location.lng;
              that.form.address =
                data.selected.data.pname +
                data.selected.data.cityname +
                data.selected.data.name +
                data.selected.data.address;
            }
          );
        })
        .catch((e) => {});
    },
    kewordSearch() {
      this.form.latitude = "";
      this.form.longitude = "";
      this.form.address = "";

      let str =
        this.form.provinceValue.area_name +
        this.form.cityValue.area_name +
        this.form.districtValue.area_name +
        this.form.keyword;

      this.placeSearch.search(str, function (status, result) {});
    },
    numSearch() {
      this.toGeoCoder();
      this.setMapMarker();
      this.$forceUpdate();
    },
    // 标记点
    setMapMarker() {
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
      this.marker = new AMap.Marker({
        map: this.map,
        position: [this.form.longitude, this.form.latitude],
      });
      this.map.setFitView();
      this.map.add(this.marker);
    },
    // 清除点
    removeMarker() {
      if (this.marker) {
        this.map.remove(this.marker);
      }
    },
    toGeoCoder() {
      let lnglat = [this.form.longitude, this.form.latitude];
      this.geoCoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.regeocode) {
          this.form.address = result.regeocode.formattedAddress;
          this.getPcd();
        }
      });
    },

    search(obj) {
      this.form.latitude = "";
      this.form.longitude = "";
      this.form.address = "";
      this.currentLevel = obj.level;
      if (obj.level == 1) {
        this.form.cityValue = "";
        this.form.districtValue = "";
        this.getCity(obj.area_id);
      }
      if (obj.level == 2) {
        this.form.districtValue = "";
        this.getArea(obj.area_id);
      }
      this.placeSearch.search(
        obj.area_name + this.form.keyword,
        function (status, result) {
          console.log(status);
          console.log(result);
          // this.form.latitude = result.poiList.
        }
      );
    },
    getData(data, level) {
      let subList = data.districtList;
      // 清空下一级别的下拉列表
      if (level === "province") {
        this.cityValue = "";
        this.districtValue = "";
        this.areaValue = "";
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.cityOptions.push(contentSub);
          }
        }
      } else if (level === "city") {
        this.form.districtValue = "";
        this.form.areaValue = "";
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.form.districtOptions.push(contentSub);
          }
        }
      } else if (level === "district") {
        this.form.areaValue = "";
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.form.areaOptions.push(contentSub);
          }
        }
      } else {
        if (subList.length > 0) {
          let contentSub = {};
          for (let i = 0; i < subList.length; i++) {
            contentSub = {
              name: subList[i].name,
              value: subList[i].name,
              center: subList[i].center,
              adcode: subList[i].adcode,
              level: subList[i].level,
            };
            this.form.provinceoptions.push(contentSub);
          }
        }
      }
    },

    clearForm() {
      this.form = {};
    },
    getProvince(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Province?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.provinceoptions = JSON.parse(
            JSON.stringify(res.data.result)
          );
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getCity(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/City?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.cityOptions = JSON.parse(JSON.stringify(res.data.result));
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
    getArea(parent_id) {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Area?parent_id=${parent_id}`,
      })
        .then((res) => {
          this.form.districtOptions = JSON.parse(
            JSON.stringify(res.data.result)
          );
        })
        .catch((err) => {
          this.$message.success(err);
        });
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.addNewActivity {
  margin-bottom: 80px;
}
.info >>> .el-form {
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size:16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.chooseBox {
  padding: 20px;
  border: 1px solid rgb(226, 226, 226);
}
.address {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  cursor: pointer;
}
.map {
  position: relative;
}
.ly {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
}
#container {
  padding: 0px;
  margin: 0px 20px;
  height: 300px;
}

#panel {
  width: 300px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
>>> .el-input {
  width: 200px;
}
>>> .el-input.ipt {
  width: 500px;
}
.bj {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 7;
}
.box {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  width: 80vw;
  max-height: 670px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
}
.boxLayout.layouts {
  position: relative;
  z-index: -100;
  visibility: hidden;
}
.boxLayout.layouts.toTop {
  z-index: 20;
  visibility: visible;
}
.cover_image_list {
  flex-wrap: wrap;
  margin-top: 20px;
}
.cover_image_list .img {
  position: relative;
  border: 1px solid #dcdcdc;
  width: 220px;
  height: 220px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.cover_image_list .img .el-icon-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
}
.cover_image_list .img .el-image {
  width: 100%;
  height: 100%;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
