<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>小程序页面配置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <h3 class="title-t mg-b-30">基本信息</h3>
      <el-form ref="form" :model="form" label-width="95px" :rules="rules">
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>公司信息</span>
            <p class="font-12 color-999">
              修改公司名称与公司LOGO（公司的LOGO图片）
            </p>
          </div>
          <el-form-item label="公司名称" prop="company_name">
            <el-input v-model="form.company_name"></el-input>
          </el-form-item>
          <el-form-item label="公司LOGO" prop="company_logo">
            <el-button
              class="avatar-uploader"
              @click="chooseBanner('company_logo')"
            >
              <img
                v-if="form.company_logo !== ''"
                :src="form.company_logo"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <i
                v-if="form.company_logo !== ''"
                class="el-icon-delete"
                @click.stop="form.company_logo = ''"
              ></i>
            </el-button>
            <p class="font-12 color-999">
              * 请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片
            </p>
          </el-form-item>
        </el-card>
        <el-card class="box-card mg-t-20" shadow="never">
          <div slot="header" class="clearfix">
            <span>小程序装修</span>
          </div>
          <div class="flex effectPreview space-between">
            <div class="mainTab">
              <el-form-item
                label="主色配置"
                class="level-bj"
                prop="master_color"
              >
                <p
                  class="colorPicker"
                  @click="showColorPicker = !showColorPicker"
                >
                  <span
                    :style="{ backgroundColor: `${form.master_color}` }"
                  ></span>
                </p>
                <Chrome
                  class="picker"
                  v-model="form.master_color"
                  v-if="showColorPicker"
                  @input="updateValue"
                  @change="headleChangeColor"
                />
              </el-form-item>
              <el-form-item label="首页名称" prop="home_name">
                <el-input v-model="form.home_name"></el-input>
              </el-form-item>
              <el-form-item prop="home_enable_icon">
                <div class="flex">
                  <div>
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('home_enable')"
                    >
                      <img
                        v-if="form.home_enable_icon !== ''"
                        :src="form.home_enable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.home_enable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.home_enable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">首页选中图标</p>
                  </div>
                  <div class="mg-l-20">
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('home_disable')"
                    >
                      <img
                        v-if="form.home_disable_icon !== ''"
                        :src="form.home_disable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.home_disable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.home_disable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">首页未选中图标</p>
                  </div>
                </div>
                <p class="font-12 color-999 mg-t-10">
                  * 请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片
                </p>
              </el-form-item>
              <el-form-item label="资讯名称" prop="information_name">
                <el-input v-model="form.information_name"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="flex">
                  <div>
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('information_enable')"
                    >
                      <img
                        v-if="form.information_enable_icon !== ''"
                        :src="form.information_enable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.information_enable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.information_enable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">资讯选中图标</p>
                  </div>
                  <div class="mg-l-20">
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('information_disable')"
                    >
                      <img
                        v-if="form.information_disable_icon !== ''"
                        :src="form.information_disable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.information_disable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.information_disable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">资讯未选中图标</p>
                  </div>
                </div>
                <p class="font-12 color-999 mg-t-10">
                  * 请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片
                </p>
              </el-form-item>
              <el-form-item label="细胞名称" prop="cell_name">
                <el-input v-model="form.cell_name"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="flex">
                  <div>
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('cell_enable')"
                    >
                      <img
                        v-if="form.cell_enable_icon !== ''"
                        :src="form.cell_enable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.cell_enable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.cell_enable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">细胞选中图标</p>
                  </div>
                  <div class="mg-l-20">
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('cell_disable')"
                    >
                      <img
                        v-if="form.cell_disable_icon !== ''"
                        :src="form.cell_disable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.cell_disable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.cell_disable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">细胞未选中图标</p>
                  </div>
                </div>
                <p class="font-12 color-999 mg-t-10">
                  * 请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片
                </p>
              </el-form-item>
              <el-form-item label="商城名称" prop="mall_name">
                <el-input v-model="form.mall_name"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="flex">
                  <div>
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('mall_enable')"
                    >
                      <img
                        v-if="form.mall_enable_icon !== ''"
                        :src="form.mall_enable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.mall_enable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.mall_enable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">商城选中图标</p>
                  </div>
                  <div class="mg-l-20">
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('mall_disable')"
                    >
                      <img
                        v-if="form.mall_disable_icon !== ''"
                        :src="form.mall_disable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.mall_disable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.mall_disable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">商城未选中图标</p>
                  </div>
                </div>
                <p class="font-12 color-999 mg-t-10">
                  * 请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片
                </p>
              </el-form-item>
              <el-form-item label="我的名称" prop="my_name">
                <el-input v-model="form.my_name"></el-input>
              </el-form-item>
              <el-form-item>
                <div class="flex">
                  <div>
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('my_enable')"
                    >
                      <img
                        v-if="form.my_enable_icon !== ''"
                        :src="form.my_enable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.my_enable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.my_enable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">我的选中图标</p>
                  </div>
                  <div class="mg-l-20">
                    <el-button
                      class="avatar-uploader"
                      @click="chooseBanner('my_disable')"
                    >
                      <img
                        v-if="form.my_disable_icon !== ''"
                        :src="form.my_disable_icon"
                        class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <i
                        v-if="form.my_disable_icon !== ''"
                        class="el-icon-delete"
                        @click.stop="form.my_disable_icon = ''"
                      ></i>
                    </el-button>
                    <p class="font-12 color-666">我的未选中图标</p>
                  </div>
                </div>
                <p class="font-12 color-999 mg-t-10">
                  * 请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片
                </p>
              </el-form-item>
            </div>
            <div class="miniProgram">
              <div class="item item1">
                <div class="box">
                  <div
                    class="statusBar flex space-between align-center mg-r-20"
                  >
                    <div class="time">8:50</div>
                    <div class="icon">
                      <el-image
                        :src="
                          require('../../../../static/images/phone-signal-full.svg')
                        "
                      ></el-image>
                      <el-image
                        :src="require('../../../../static/images/wifi.svg')"
                      ></el-image>
                      <el-image
                        :src="
                          require('../../../../static/images/electricity-full.svg')
                        "
                      ></el-image>
                    </div>
                  </div>
                  <div
                    class="logo2 mg-t-20 flex space-between align-center mg-r-20"
                  >
                    <el-image :src="form.company_logo"></el-image>
                    <div class="flex space-around align-center">
                      <el-image
                        :src="
                          require('../../../../static/images/xiaochengxu1.svg')
                        "
                      ></el-image>
                      <el-divider direction="vertical"></el-divider>
                      <el-image
                        :src="
                          require('../../../../static/images/xiaochengxu-guanbi.svg')
                        "
                      ></el-image>
                    </div>
                  </div>
                  <div class="jingang mg-t-20 flex space-between mg-r-20">
                    <div>
                      <el-image :src="form.kong[0].image"></el-image>
                      <p class="text-center">{{ form.kong[0].name }}</p>
                    </div>
                    <div>
                      <el-image :src="form.kong[1].image"></el-image>
                      <p class="text-center">{{ form.kong[1].name }}</p>
                    </div>
                    <div>
                      <el-image :src="form.kong[2].image"></el-image>
                      <p class="text-center">{{ form.kong[2].name }}</p>
                    </div>
                    <div>
                      <el-image :src="form.kong[3].image"></el-image>
                      <p class="text-center">{{ form.kong[3].name }}</p>
                    </div>
                    <div>
                      <el-image :src="form.kong[4].image"></el-image>
                      <p class="text-center">{{ form.kong[4].name }}</p>
                    </div>
                    <!-- <div v-if="form.kong[5].image !=='' && form.kong[5].name !== ''">
                      <el-image :src="form.kong[5].image"></el-image>
                      <p class="text-center">{{form.kong[5].name}}</p>
                    </div> -->
                  </div>
                  <div class="slider mg-t-20 mg-r-20">
                    <el-image
                      :src="require('../../../../static/images/slide01.jpg')"
                    ></el-image>
                  </div>
                </div>
              </div>
              <div class="item item2">
                <div
                  class="notice flex space-between align-center"
                  :style="{ background: form.master_color }"
                >
                  <p>
                    <i class="iconfont icon-notice mg-r-10"></i
                    >消息公告：五一放假通知～
                  </p>
                  <i class="iconfont icon-arrow" style="font-size: 20px"></i>
                </div>
              </div>
              <div class="addervise item">
                <el-image
                  :src="
                    require('../../../../static/images/home_huiyuanzhongxin.jpg')
                  "
                ></el-image>
              </div>
              <div class="item item3">
                <h2>
                  <span :style="{ background: form.master_color }"></span
                  >活动推荐
                </h2>
                <div class="box mg-t-20">
                  <el-image
                    :src="require('../../../../static/images/slide01.jpg')"
                  ></el-image>
                </div>
              </div>
              <div class="item tab">
                <div class="flex space-around align-center text-center">
                  <div>
                    <img
                      v-if="form.home_enable_icon !== ''"
                      :src="form.home_enable_icon"
                      class="avatar"
                    />
                    <i
                      v-else
                      class="iconfont icon-home"
                      :style="{ color: form.master_color }"
                    ></i>
                    <p :style="{ color: form.master_color }">
                      {{ form.home_name }}
                    </p>
                  </div>
                  <div>
                    <img
                      v-if="form.information_disable_icon !== ''"
                      :src="form.information_disable_icon"
                      class="avatar"
                    />
                    <i v-else class="iconfont icon-guide"></i>
                    <p>{{ form.information_name }}</p>
                  </div>
                  <div>
                    <img
                      v-if="form.mall_disable_icon !== ''"
                      :src="form.mall_disable_icon"
                      class="avatar"
                    />
                    <i v-else class="iconfont icon-shop"></i>
                    <p>{{ form.mall_name }}</p>
                  </div>
                  <div>
                    <img
                      v-if="form.my_disable_icon !== ''"
                      :src="form.my_disable_icon"
                      class="avatar"
                    />
                    <i v-else class="iconfont icon-my"></i>
                    <p>{{ form.my_name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-card>
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>金刚区装修</span>
          </div>
          <div class="iconSquare">
            <div
              v-for="(item, index) in form.kong"
              :key="index"
              class="mg-b-50"
            >
              <el-form-item :label="'金刚区' + squareList[index]">
                <el-input v-model="item.name"></el-input>
              </el-form-item>
              <el-form-item label="图标" prop="name">
                <el-button class="avatar-uploader" @click="chooseBanner(index)">
                  <img
                    v-if="item.image !== ''"
                    :src="item.image"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <i
                    v-if="item.image !== ''"
                    class="el-icon-delete"
                    @click.stop="item.image = ''"
                  ></i>
                </el-button>
                <p class="font-12 color-999">
                  * 请按60*60尺寸上传png/jpg格式且小于2m的图片
                </p>
              </el-form-item>
              <el-form-item label="跳转类型" prop="type">
                <el-radio v-model="item.type" label="1">链接</el-radio>
                <el-radio v-model="item.type" label="2">富文本</el-radio>
                <el-radio v-model="item.type" label="4">视频</el-radio>
                <el-radio v-model="item.type" label="5">商品</el-radio>
                <el-radio v-model="item.type" label="6">项目</el-radio>
                <el-radio v-model="item.type" label="7">客服</el-radio>
                <el-radio v-model="item.type" label="8">秒杀</el-radio>
                <el-radio v-model="item.type" label="9">外部小程序</el-radio>
              </el-form-item>
              <el-form-item label="小程序AppId" v-if="item.type == '9'">
                <el-input
                  v-model="item.mini_appid"
                  placeholder="如果是外部小程序，请输入小程序的appid"
                ></el-input>
              </el-form-item>
              <el-form-item label="页面地址" v-if="item.type == '9'">
                <el-input v-model="item.mini_page" placeholder="请输入外部小程序的页面地址"></el-input>
              </el-form-item>
              <el-form-item
                label="链接地址"
                :prop="item.type == 1 ? 'kong.' + index + '.url' : ''"
                v-if="item.type == 1"
                :rules="{ required: true, message: '请输入' }"
              >
                <el-select v-model="item.url" placeholder="请选择">
                  <el-option
                    v-for="jtem in urlList"
                    :key="jtem.value"
                    :label="jtem.label"
                    :value="jtem.value"
                  >
                  </el-option>
                </el-select>
                <!-- <el-input  v-model="item.url"></el-input> -->
              </el-form-item>
              <el-form-item label="富文本内容" v-if="item.type == 2">
                <span slot="label"
                  ><span style="color: red">*</span>富文本内容</span
                >
                <wangEditorTool :ref="'editors' + index" :content="item.text" />
              </el-form-item>
              <el-form-item label="选择视频" v-if="item.type == '4'">
                <el-button class="avatar-uploader video" @click="chooseVideo(index)">
                  <video v-if="item.movie !== null" width="178px" controls>
                    <source :src="item.movie" />
                  </video>
                  <i
                    class="el-icon-delete"
                    v-if="item.movie !== null"
                    @click.stop="item.movie = null"
                  ></i>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-button>
              </el-form-item>
              <el-form-item label="选择商品" v-if="item.type == 5">
                <el-button
                  v-if="item.goods.length == 0"
                  @click="showSelectCommdityDialogVisible(index, 'add')"
                  class="mg-l-20"
                  type="text"
                >
                  + 选择商品</el-button
                >
                <el-button
                  v-else
                  @click="showSelectCommdityDialogVisible(index, 'edit')"
                  class="mg-l-20"
                  type="text"
                >
                  + 修改商品</el-button
                >

                <el-table
                  :data="item.goods"
                  border
                  v-if="item.goods && item.goods.length > 0"
                >
                  <el-table-column type="index" label="序号" width="55">
                  </el-table-column>
                  <el-table-column prop="name" label="商品名"></el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item label="选择项目" v-if="item.type == '6'">
                <el-button
                  v-if="item.item.length == 0"
                  @click="showProjectDialog(index, 'add')"
                  class="mg-l-20"
                  type="text"
                >
                  + 选择项目</el-button
                >
                <el-button
                  v-else
                  @click="showProjectDialog(index, 'edit')"
                  class="mg-l-20"
                  type="text"
                  >修改项目</el-button
                >

                <el-table :data="item.item" border v-if="item.item.length > 0">
                  <el-table-column type="index" label="序号" width="55">
                  </el-table-column>
                  <el-table-column prop="name" label="项目名"></el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item label="选择秒杀" v-if="item.type == '8'">
                <el-button
                  v-if="item.seckillList.length == 0"
                  @click="showSelectSeckillDialogVisible(index,'add')"
                  class="mg-l-20"
                  type="text"
                >
                  + 选择秒杀</el-button
                >
                <el-button
                  v-else
                  @click="showSelectSeckillDialogVisible(index,'edit')"
                  class="mg-l-20"
                  type="text"
                >
                  + 修改秒杀</el-button
                >

                <el-table
                  :data="item.seckillList"
                  border
                  v-if="item.seckillList.length > 0"
                >
                  <el-table-column type="index" label="序号" width="55">
                  </el-table-column>
                  <el-table-column prop="name" label="秒杀活动名称"></el-table-column>
                </el-table>
              </el-form-item>
            </div>
          </div>
        </el-card>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @selectImg="selectImg" @insertVideoHandler="insertVideoHandler"/>
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <selectCommdity
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      :isSingle="true"
      :goods="goods"
      @closeDialog="selectCommdityDialogVisible = false"
    />
    <el-dialog
      title="选择项目"
      :visible.sync="dialogProjectVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <div class="mg-t-10 mg-b-10">
        <el-input v-model="search_keyword" @input="getItemList" style="width: 400px" placeholder="请输入项目名称"></el-input>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.image.length > 0"
                :src="scope.row.image[0].image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="项目编码" :align="'center'">
        </el-table-column>
        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column
          prop="sales_number"
          label="销量"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column prop="category.name" label="分类" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >出售中</span
            >
            <span v-if="scope.row.status == 3">已售完</span>
            <span v-if="scope.row.status == 2">已下架</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogProjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmProject">确定</el-button>
      </span>
    </el-dialog>
    <selectSeckillVue 
     :dialogVisible="selectSeckillDialogVisible"
      @confirmCommidity="confirmSeckill"
      :isSingle="true"
      :goods="miaoshaList"
    />
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible2"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />
      
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import wangEditorTool from "../../../components/wangEditorTool.vue";
import selectCommdity from "../../../components/selectCommdity";
import pagination from "../../../components/pagination.vue";
import selectSeckillVue from "../../../components/selectSeckill.vue";
import imageDialog from "../../../components/imageDialog.vue";
import { Chrome } from "vue-color";
export default {
  name: "miniProgramPageConfig",
  components: {
    Chrome,
    MaterialLibrary,
    wangEditorTool,
    selectCommdity,
    pagination,
    imageDialog,
    selectSeckillVue
  },
  data() {
    return {
      dialogVisible2: false,
      selectSeckillDialogVisible: false,
      goods: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      search_keyword: '',
      showColorPicker: false,
      dialogVisible: false,
      dialogProjectVisible: false,
      uploadType: "",
      imgType: "",
      urlList: [
        {
          label: "会员中心",
          value: "/myPage/vip/benefit/benefit",
        },
        {
          label: "签到",
          value: "/myPage/vip/sign/sign",
        },
        {
          label: "项目咨询（项目列表）",
          value: "/pages/mall/mall?current=1",
        },
        {
          label: "积分商城",
          value: "/pages/mall/mall?current=0",
        },
        {
          label: "会员商城",
          value: "/vipPage/rights/rights",
        },
        {
          label: "我的卡券",
          value: "/myPage/wallet/coupons/coupons",
        },
        {
          label: "任务中心",
          value: "/vipPage/meberTask/meberTask",
        },
        {
          label: "关于博雅",
          value: "/vipPage/about/about",
        },
        {
          label: "首页",
          value: "/pages/index/index",
        },
        {
          label: "资讯",
          value: "/pages/information/information",
        },
        {
          label: "我的",
          value: "/pages/my/my",
        },
      ],
      squareList: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十"],
      color: "orange",
      bgColor: "orange",
      lightBgColor: "orange",
      chooseIndex: '',
      form: {
        company_name: "",
        company_logo: require("../../../../static/images/logo2.svg"),
        home_enable_icon: "",
        home_disable_icon: "",
        master_color: "orange",
        home_name: "首页",
        information_name: "资讯",
        information_enable_icon: "",
        information_disable_icon: "",
        cell_name: "细胞",
        cell_enable_icon: "",
        cell_disable_icon: "",
        mall_name: "商城",
        mall_enable_icon: "",
        mall_disable_icon: "",
        my_name: "我的",
        my_enable_icon: "",
        my_disable_icon: "",
        kong: [
          {
            name: "项目咨询",
            image: require("../../../../static/images/home_icon_xiangmuzixun.svg"),
            type: "",
            url: "",
            text: "",
            minigram: "",
            movie: null,
            mini_appid: '',
            mini_page: "",
            goods_uuid: "",
            item_uuid: "",
            miaosha_uuid: '',
            goods: [],
            item: [],
            seckillList: []
          },
          {
            name: "积分商城",
            image: require("../../../../static/images/home_icon_jifenshangcheng.svg"),
            type: "",
            url: "",
            text: "",
            minigram: "",
            movie: null,
            mini_appid: '',
            mini_page: "",
            goods_uuid: "",
            item_uuid: "",
            miaosha_uuid: '',
            goods: [],
            item: [],
            seckillList: []
          },
          {
            name: "我的卡券",
            image: require("../../../../static/images/home_icon_wodekaquan.svg"),
            type: "",
            url: "",
            text: "",
            minigram: "",
            movie: null,
            mini_appid: '',
            mini_page: "",
            goods_uuid: "",
            item_uuid: "",
            miaosha_uuid: '',
            goods: [],
            item: [],
            seckillList: []
          },
          {
            name: "任务中心",
            image: require("../../../../static/images/home_icon_renwuzhongxin.svg"),
            type: "",
            url: "",
            text: "",
            minigram: "",
            movie: null,
            mini_appid: '',
            mini_page: "",
            goods_uuid: "",
            item_uuid: "",
            miaosha_uuid: '',
            goods: [],
            item: [],
            seckillList: []
          },
          {
            name: "关于博雅",
            image: require("../../../../static/images/home_icon_guanyuboya.svg"),
            type: "",
            url: "",
            text: "",
            minigram: "",
            movie: null,
            mini_appid: '',
            mini_page: "",
            goods_uuid: "",
            item_uuid: "",
            miaosha_uuid: '',
            goods: [],
            item: [],
            seckillList: []
          },
        ],
      },
      rules: {
        company_name: {
          required: true,
          message: "请输入公司名称",
          trigger: "blur",
        },
        company_logo: {
          required: true,
          message: "请上传公司logo",
          trigger: "blur",
        },
        home_name: {
          required: true,
          message: "请输入首页名称",
          trigger: "blur",
        },
        information_name: {
          required: true,
          message: "请输入首页名称",
          trigger: "blur",
        },
        cell_name: {
          required: true,
          message: "请输入首页名称",
          trigger: "blur",
        },
        mall_name: {
          required: true,
          message: "请输入首页名称",
          trigger: "blur",
        },
        my_name: { required: true, message: "请输入首页名称", trigger: "blur" },
      },
      logo: require("../../../../static/images/logo2.svg"),
      jingang1: require("../../../../static/images/home_icon_xiangmuzixun.svg"),
      jingang2: require("../../../../static/images/home_icon_jifenshangcheng.svg"),
      jingang3: require("../../../../static/images/home_icon_wodekaquan.svg"),
      jingang4: require("../../../../static/images/home_icon_renwuzhongxin.svg"),
      jingang5: require("../../../../static/images/home_icon_guanyuboya.svg"),
      jingang6: "",
      dialogVideoVisible: false,
      title: '',
      selectCommdityDialogVisible: false,
      selectRows: [],
      miaoshaList: []
    };
    
  },
  created() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: ` https://time.api.boyalife.cn/v1/cms/PageSetting/1`,
    }).then((res) => {
      res.data.result.kong
      this.form = res.data.result;
      this.form.kong.splice(5, 1);
      // this.form.kong.map(item => {
      //   item.movie= null
      //   item.mini_appid= ''
      //   item.mini_page= ""
      //   item.goods_uuid= ""
      //   item.item_uuid= ""
      //   item.miaosha_uuid= ''
      //   item.item = []
      //   item.goods = []
      //   item.seckillList = []
      // })
      console.log(this.form.kong)
    });
   
  },
  mounted () {
    
  },
  methods: {
    confirmImageDialog(item) {
      this.dialogVisible = false;
      switch (this.uploadType) {
        case "company_logo":
          this.form.company_logo = item[0].url;
          break;
        case "home_enable":
          this.form.home_enable_icon = item[0].url;
          break;
        case "home_disable":
          this.form.home_disable_icon = item[0].url;
          break;
        case "information_enable":
          this.form.information_enable_icon = item[0].url;
          break;
        case "information_disable":
          this.form.information_disable_icon = item[0].url;
          break;
        case "cell_enable":
          this.form.cell_enable_icon = item[0].url;
          break;
        case "cell_disable":
          this.form.cell_disable_icon = item[0].url;
          break;
        case "mall_enable":
          this.form.mall_enable_icon = item[0].url;
          break;
        case "mall_disable":
          this.form.mall_disable_icon = item[0].url;
          break;
        case "my_enable":
          this.form.my_enable_icon = item[0].url;
          break;
        case "my_disable":
          this.form.my_disable_icon = item[0].url;
          break;
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          this.form.kong[this.uploadType].image = item[0].url;
          break;
      }
      this.uploadType = "";
      this.dialogVisible2 = false;
    },
    closeImageDialog () {
      this.dialogVisible2 = false
    },
    showSelectSeckillDialogVisible (index, type) {
      if (type == 'add') {
        this.miaoshaList = []
        this.form.kong[index].seckillList = []
      }
      this.chooseIndex = index
      this.selectSeckillDialogVisible = true
    },
    confirmSeckill (v) {
      this.form.kong[this.chooseIndex].seckillList = []
      this.form.kong[this.chooseIndex].seckillList.push(v);
      this.form.kong[this.chooseIndex].miaosha_uuid = v.uuid;
      this.selectSeckillDialogVisible = false
    },
    handleSelectionChange(rows) {
      if (rows.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(rows[rows.length - 1]);
      }
      this.selectRows = rows[rows.length - 1];
      if (!this.selectRows) {
        this.selectRows = {};
      }
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getItemList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getItemList();
    },
    confirmProject() {
      this.form.kong[this.chooseIndex].item = [];
      this.form.kong[this.chooseIndex].item.push(this.selectRows);
      this.form.kong[this.chooseIndex].item_uuid = this.selectRows.uuid;
      this.dialogProjectVisible = false;
    },
    showProjectDialog(index,type) {
      if (type == 'add') {
        this.form.kong[index].item = []
      }
      this.chooseIndex = index
      this.dialogProjectVisible = true;
      this.getItemList();
    },
    // 获取项目列表
    getItemList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: `https://time.api.boyalife.cn/v1/cms/Item?page_index=${this.tableData.current_page}&search_keyword=${this.search_keyword}&page_size=${this.tableData.per_page}&search_status=1`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    confirmCommidity(v) {
      this.form.kong[this.chooseIndex].goods = [];
      this.form.kong[this.chooseIndex].goods.push(v);
      this.form.kong[this.chooseIndex].goods_uuid = v.uuid;
      this.selectCommdityDialogVisible = false
    },
    showSelectCommdityDialogVisible (index, type) {
      if (type == 'add') {
        this.goods = []
      }
      this.selectCommdityDialogVisible = true
      this.chooseIndex = index
    },
    chooseVideo(index) {
      this.chooseIndex = index
      this.imgType = "chooseVideo";
      this.dialogVisible = true;
      this.title = "选择视频";
    },
    insertVideoHandler(url) {
      this.dialogVisible = false;
      this.form.kong[this.chooseIndex].movie = url;
    },
    closed() {
      this.dialogVisible = false;
    },
    // 选择banner
    chooseBanner(type) {
      this.title= '选择图片'
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible2 = true;
      this.uploadType = type;
    },
    selectImg(item) {
      this.dialogVisible = false;
      switch (this.uploadType) {
        case "company_logo":
          this.form.company_logo = item.url;
          break;
        case "home_enable":
          this.form.home_enable_icon = item.url;
          break;
        case "home_disable":
          this.form.home_disable_icon = item.url;
          break;
        case "information_enable":
          this.form.information_enable_icon = item.url;
          break;
        case "information_disable":
          this.form.information_disable_icon = item.url;
          break;
        case "cell_enable":
          this.form.cell_enable_icon = item.url;
          break;
        case "cell_disable":
          this.form.cell_disable_icon = item.url;
          break;
        case "mall_enable":
          this.form.mall_enable_icon = item.url;
          break;
        case "mall_disable":
          this.form.mall_disable_icon = item.url;
          break;
        case "my_enable":
          this.form.my_enable_icon = item.url;
          break;
        case "my_disable":
          this.form.my_disable_icon = item.url;
          break;
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          this.form.kong[this.uploadType].image = item.url;
          break;
      }
      this.uploadType = "";
    },
    updateValue(v) {
      this.form.master_color = v.hex;
      this.showColorPicker = false;
    },
    submitForm(form) {
      let flag = true;
      this.form.kong.map((item, index) => {
        if (item.type == 2) {
          if (
            this.$refs["editors" + index][0].html !== "" &&
            this.$refs["editors" + index][0].html !== "<p><br></p>"
          ) {
            item.text = this.$refs["editors" + index][0].html;
            flag = true;
          } else {
            this.$message.warning("富文本不能为空！");
            flag = false;
            return false;
          }
        }
      });
      if (!flag) {
        return false;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (
            this.form.home_enable_icon == "" ||
            this.form.home_disable_icon == ""
          ) {
            this.$message.error(`${this.form.home_name}icon不能为空`);
            return false;
          } else if (
            this.form.information_enable_icon == "" ||
            this.form.information_disable_icon == ""
          ) {
            this.$message.error(`${this.form.information_name}icon不能为空`);
            return false;
          } else if (
            this.form.cell_enable_icon == "" ||
            this.form.cell_disable_icon == ""
          ) {
            this.$message.error(`${this.form.cell_name}icon不能为空`);
            return false;
          } else if (
            this.form.mall_enable_icon == "" ||
            this.form.mall_disable_icon == ""
          ) {
            this.$message.error(`${this.form.mall_name}icon不能为空`);
            return false;
          } else if (
            this.form.my_enable_icon == "" ||
            this.form.my_disable_icon == ""
          ) {
            this.$message.error(`${this.form.my_name}icon不能为空`);
            return false;
          }
        
          this.axios({
            method: "put", //请求方法
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": this.storage.get("__token"),
              Authorization: `Bearer ${this.storage.get("__token")}`,
            },
            data: { ...this.form },
            url: ` https://time.api.boyalife.cn/v1/cms/PageSetting/1`,
          }).then(() => {
            this.$message.success("保存成功！");
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
>>> .el-form-item {
  width: 600px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader img {
  width: 50px;
  height: 50px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar-uploader .el-icon-delete {
  font-size: 15px;
  right: 0;
}
.effectPreview {
  padding: 20px;
}
.effectPreview > div {
  flex: 1;
}
.colorPicker {
  width: 50px;
  height: 25px;
  padding: 5px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.colorPicker span {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.colorPicker span.bg {
  background: #fe9200;
}
.iconSquare {
  flex-wrap: wrap;
}
.iconSquare > div {
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(234, 234, 234);
}
.iconSquare > div >>> .el-form-item__content,
.mainTab >>> .el-form-item__content {
  width: 50%;
}
.miniProgram {
  width: 710px;
  background: rgb(243, 243, 243);
  padding: 20px;
}
.miniProgram .item.item1 {
  height: 460px;
  background: url(../../../../static/images/home_BG.jpg) no-repeat;
  background-size: contain;
  position: relative;
}
.miniProgram .item1 .box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
}
.miniProgram .item {
  background: #fff;
  padding: 0 20px;
}
.miniProgram .item1 .box .icon .el-image {
  width: 18px;
  margin-right: 5px;
}

.logo2 .el-image {
  width: 120px;
}
.el-divider--vertical {
  margin: 0 -20px;
}
.logo2 .flex {
  width: 94px;
  height: 34px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  border: 1px solid rgba(151, 151, 151, 0.2);
}
.el-image {
  max-width: 100%;
}
.logo2 .flex .el-image {
  width: 20px;
}
.jingang .el-image {
  width: 60px;
  height: 60px;
}
.notice {
  border-radius: 4px;
  padding: 5px 20px;
}
.addervise .el-image {
  max-width: 100%;
}

.item3 h2 {
  position: relative;
  padding-left: 20px;
}
.item3 h2 span {
  display: inline-block;
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.miniProgram .item.tab {
  padding: 20px 0;
  padding-bottom: 30px;
}
.tab .iconfont {
  font-size: 20px;
}
.tab img {
  width: 22px;
  height: 22px;
}
.el-button {
  padding: 12px;
}
.layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 8;
  left: 0;
  top: 0;
}
.layout .bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 7;
}
.layout .el-icon-circle-close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 10;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.matreial {
  width: 1050px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}
</style>
