<template>
  <div class="addBanner">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容</el-breadcrumb-item>
      <el-breadcrumb-item :to="'/bannerList'">banner列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageType }}banner</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="info boxLayout mg-t-20">
      <h3 class="title-t mg-b-30">基础信息</h3>
      <el-form
        ref="form"
        :model="basicInfoForm"
        label-width="140px"
        :rules="rules"
      >
        <el-form-item label="标题" prop="name">
          <el-input v-model="basicInfoForm.name"></el-input>
        </el-form-item>
        <el-form-item label="banner图" prop="image">
          <el-upload
            ref="upload"
            class="upload-demo"
            action="#"
            :accept="'jpg,jpeg,png'"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :http-request="httpRequest"
          >
          <img
              v-if="basicInfoForm.image != ''"
              :src="basicInfoForm.image"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
          </el-upload>
          <el-button  type="text"  @click="chooseBanner">从素材库选择</el-button>
          <p class="font-12 color-999">建议尺寸：750*600像素</p>
        </el-form-item>
        <el-form-item label="权重">
          <el-input v-model="basicInfoForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="banner位置" prop="position">
          <el-select v-model="basicInfoForm.position" placeholder="请选择">
            <el-option
              v-for="item in positionList"
              :key="item.value"
              :label="item.value"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转类型" prop="url_type">
          <el-radio v-model="basicInfoForm.url_type" :label="1">链接</el-radio>
          <el-radio v-model="basicInfoForm.url_type" :label="2"
            >富文本</el-radio
          >
          <!-- <el-radio v-model="basicInfoForm.url_type" :label="3">小程序</el-radio> -->
          <el-radio v-model="basicInfoForm.url_type" :label="4">视频</el-radio>
          <el-radio v-model="basicInfoForm.url_type" :label="5">商品</el-radio>
          <el-radio v-model="basicInfoForm.url_type" :label="6">项目</el-radio>
          <el-radio v-model="basicInfoForm.url_type" :label="7">客服</el-radio>
          <el-radio v-model="basicInfoForm.url_type" :label="8">秒杀</el-radio>
          <el-radio v-model="basicInfoForm.url_type" :label="9">外部小程序</el-radio>
        </el-form-item>
        <el-form-item label="小程序AppId" v-if="basicInfoForm.url_type == '9'">
          <el-input
            v-model="basicInfoForm.mini_appid"
            placeholder="如果是外部小程序，请输入小程序的appid"
          ></el-input>
        </el-form-item>
        <el-form-item label="页面地址" v-if="basicInfoForm.url_type == '9'">
          <el-input v-model="basicInfoForm.mini_page" placeholder="请输入外部小程序的页面地址"></el-input>
        </el-form-item>
        
        <el-form-item
          label="链接地址"
          v-if="basicInfoForm.url_type == '1'"
          :prop="basicInfoForm.url_type == '1' ? 'url' : ''"
        >
          <el-select v-model="basicInfoForm.url" placeholder="请选择">
            <el-option
              v-for="jtem in urlList"
              :key="jtem.value"
              :label="jtem.label"
              :value="jtem.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="富文本内容" v-if="basicInfoForm.url_type == '2'">
          <wangEditorTool ref="editors" :content="basicInfoForm.text" />
        </el-form-item>
        <!-- <el-form-item label="页面地址" v-if="basicInfoForm.url_type == '3'" :prop="basicInfoForm.url_type == 3 ? 'mini_page' : ''">
          <el-input v-model="basicInfoForm.mini_page"></el-input>
        </el-form-item> -->
        <el-form-item label="选择视频" v-if="basicInfoForm.url_type == '4'">
          <el-button class="avatar-uploader video" @click="chooseVideo">
            <video v-if="basicInfoForm.movie !== null" width="178px" controls>
              <source :src="basicInfoForm.movie" />
            </video>
            <i
              class="el-icon-delete"
              v-if="basicInfoForm.movie !== null"
              @click.stop="basicInfoForm.movie = null"
            ></i>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-button>
        </el-form-item>
        <el-form-item label="选择商品" v-if="basicInfoForm.url_type == '5'">
          <el-button
            v-if="commidityList.length == 0"
            @click="selectCommdityDialogVisible = true"
            class="mg-l-20"
            type="text"
          >
            + 选择商品</el-button
          >
          <el-button
            v-else
            @click="selectCommdityDialogVisible = true"
            class="mg-l-20"
            type="text"
          >
            + 修改商品</el-button
          >

          <el-table
            :data="commidityList"
            border
            v-if="commidityList.length > 0"
          >
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="商品名"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="选择项目" v-if="basicInfoForm.url_type == '6'">
          <el-button
            v-if="projectList.length == 0"
            @click="showProjectDialog"
            class="mg-l-20"
            type="text"
          >
            + 选择项目</el-button
          >
          <el-button
            v-else
            @click="showProjectDialog"
            class="mg-l-20"
            type="text"
            >修改项目</el-button
          >

          <el-table :data="projectList" border v-if="projectList.length > 0">
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="项目名"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="选择秒杀" v-if="basicInfoForm.url_type == '8'">
          <el-button
            v-if="seckillList.length == 0"
            @click=" selectSeckillDialogVisible = true"
            class="mg-l-20"
            type="text"
          >
            + 选择秒杀</el-button
          >
          <el-button
            v-else
            @click="selectSeckillDialogVisible = true"
            class="mg-l-20"
            type="text"
          >
            + 修改秒杀</el-button
          >

          <el-table
            :data="seckillList"
            border
            v-if="seckillList.length > 0"
          >
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="name" label="秒杀活动名称"></el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="上架时间" prop="shelf_type">
          <el-radio v-model="basicInfoForm.shelf_type" :label="1"
            >立即上架</el-radio
          >
          <el-radio v-model="basicInfoForm.shelf_type" :label="2"
            >定时上架</el-radio
          >
          <el-date-picker
            v-if="basicInfoForm.shelf_type == 2"
            v-model="basicInfoForm.shelf_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="投放人群" prop="group_uuid">
          <el-select
            multiple
            collapse-tags
            v-model="basicInfoForm.group_uuid"
            placeholder="请选择"
          >
            <el-option
              v-for="(jtem, i) in userGroupList"
              :key="i"
              :label="jtem"
              :value="i"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button type="primary" size="mini" @click="submitForm('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择项目"
      :visible.sync="dialogProjectVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <!-- <div class="mg-t-10 mg-b-10">
        <el-input v-model="search_keyword" @input="getItemList"></el-input>
      </div> -->
      <el-table
        ref="multipleTable"
        :data="tableData.data"
        border
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="商品描述"
          :align="'center'"
          prop="nickName"
          width="300"
        >
          <template slot-scope="scope">
            <div class="nickAndAvatar">
              <el-image
                v-if="scope.row.image.length > 0"
                :src="scope.row.image[0].image"
              ></el-image>
              <div style="width: 200px">
                <el-tooltip :content="scope.row.name" placement="top">
                  <p class="ellipsis">
                    {{ scope.row.name }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="项目编码" :align="'center'">
        </el-table-column>
        <el-table-column prop="price" label="价格" :align="'center'" sortable>
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column
          prop="sales_number"
          label="销量"
          :align="'center'"
          sortable
        >
        </el-table-column>
        <el-table-column prop="category.name" label="分类" :align="'center'">
        </el-table-column>
        <el-table-column prop="status" label="状态" :align="'center'">
          <template slot-scope="scope">
            <span style="color: #2ed477" v-if="scope.row.status == 1"
              >出售中</span
            >
            <span v-if="scope.row.status == 3">已售完</span>
            <span v-if="scope.row.status == 2">已下架</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          :align="'center'"
          width="160"
        >
        </el-table-column>
      </el-table>
      <pagination
        :total="tableData.total"
        :per_page="tableData.per_page"
        :current_page="tableData.current_page"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogProjectVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmProject">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog
      :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog"
      />
    
    </el-dialog>
    <selectCommdity
      :dialogVisible="selectCommdityDialogVisible"
      @confirmCommidity="confirmCommidity"
      :isSingle="true"
    />
    <selectSeckillVue 
     :dialogVisible="selectSeckillDialogVisible"
      @confirmCommidity="confirmSeckill"
      :isSingle="true"
    />
    <el-dialog
      title="选择视频"
      :visible.sync="dialogVideoVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary
        :type="imgType"
        @insertVideoHandler="insertVideoHandler"
      />
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVideoVisible = false">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>

import MaterialLibrary from "../../../components/materialLibraryDialog/index";

import wangEditorTool from "../../../components/wangEditorTool.vue";
import selectCommdity from "../../../components/selectCommdity";
import imageDialog from "../../../components/imageDialog.vue";
import pagination from "../../../components/pagination.vue";
import selectSeckillVue from "../../../components/selectSeckill.vue";
export default {
  name: "addBanner",
  components: { imageDialog, wangEditorTool, selectCommdity, pagination, selectSeckillVue ,MaterialLibrary},
  data() {
    return {
      search_keyword: "",
      selectCommdityDialogVisible: false,
      selectSeckillDialogVisible: false,
      dialogProjectVisible: false,
      commidityList: [],
      seckillList: [],
      projectList: [],
      tableData: {
        data: [],
        per_page: 10,
        current_page: 1,
        total: 0,
      },
      selectRows: [],
      isShow: "",
      imgType: "",
      pageType: "",
      dialogVisible: false,
      dialogVideoVisible:false,
      userGroupList: [],
      urlList: [
        {
          label: "会员中心",
          value: "/myPage/vip/benefit/benefit",
        },
        {
          label: "会员权益",
          value: "/vipPage/rights/rights",
        },
        {
          label: "签到",
          value: "/myPage/vip/sign/sign",
        },
        {
          label: "项目咨询（项目列表）",
          value: "/pages/mall/mall?current=1",
        },
        {
          label: "积分商城",
          value: "/pages/mall/mall?current=0",
        },
        {
          label: "我的卡券",
          value: "/myPage/wallet/coupons/coupons",
        },
        {
          label: "任务中心",
          value: "/vipPage/meberTask/meberTask",
        },
        {
          label: "关于博雅",
          value: "/vipPage/about/about",
        },
        {
          label: "首页",
          value: "/pages/index/index",
        },
        {
          label: "资讯",
          value: "/pages/information/information",
        },
        {
          label: "我的",
          value: "/pages/my/my",
        },
        {
          label: "宠物列表",
          value: "/myCellPage/petPackage/petPackage",
        },
        {
          label: "敬请期待",
          value: "/myCellPage/beingDeveloped/beingDeveloped",
        },
      ],

      positionList: [],
      basicInfoForm: {
        name: "",
        status: "",
        image: "",
        sort: "",
        position: "",
        url_type: 1,
        url: "",
        shelf_type: 1,
        shelf_time: "",
        movie: null,
        mini_appid: '',
        mini_page: "",
        goods_uuid: "",
        item_uuid: "",
        item: null,
        goods: null,
        miaosha_uuid: ''
      },
      options: [],
      rules: {
        name: [
          { required: true, message: "请填写banner标题", trigger: "blur" },
        ],
        url: { required: true, message: "请选择", trigger: "change" },
        mini_page: {
          required: true,
          message: "请填写小程序地址",
          trigger: "blur",
        },

        image: [{ required: true, message: "请选择banner", trigger: "blur" }],
        shelf_type: [
          { required: true, message: "请选择上架时间", trigger: "blur" },
        ],
        position: {
          required: true,
          message: "请选择banner位置",
          trigger: "change",
        },
      },
      formData: ''
    };
  },
  created() {
    this.getBannerPosition();
    this.getUserGroup();
    if (Object.keys(this.$route.query).length !== 0) {
      this.pageType = "编辑";
      this.getInfo();
    } else {
      this.pageType = "新增";
    }
  },
  methods: {
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.image =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    getInfo() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Banner/${this.$route.query.uuid}`,
      })
        .then((res) => {
          let params = res.data.result;
          for (let i in params) {
            this.basicInfoForm[i] = JSON.parse(JSON.stringify(params[i]));
          }
          this.basicInfoForm.group.length > 0
            ? (this.basicInfoForm.group_uuid = this.basicInfoForm.group.map(
                (item) => item.uuid
              ))
            : [];
          this.basicInfoForm.shelf_type = Number(this.basicInfoForm.shelf_type);
          this.basicInfoForm.position = Number(this.basicInfoForm.position);
          this.basicInfoForm.url_type = Number(this.basicInfoForm.url_type);
          if (this.basicInfoForm.item != null) {
            this.projectList = [];
            this.projectList.push(this.basicInfoForm.item);
          }
          if (this.basicInfoForm.goods != null) {
            this.commidityList = [];
            this.commidityList.push(this.basicInfoForm.goods);
          }
          if (this.basicInfoForm.miaosha != null) {
            this.seckillList = [];
            this.seckillList.push(this.basicInfoForm.miaosha);
          }
          console.log(this.projectList);
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    getBannerPosition() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: `https://time.api.boyalife.cn/v1/app/BannerPosition`,
      })
        .then((res) => {
          this.positionList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    confirmProject() {
      this.dialogProjectVisible = false;
      this.projectList = [];
      this.projectList.push(this.selectRows);
      this.basicInfoForm.item_uuid = this.selectRows.uuid;
    },
    handleSelectionChange(rows) {
      if (rows.length > 1) {
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(rows[rows.length - 1]);
      }
      this.selectRows = rows[rows.length - 1];
      if (!this.selectRows) {
        this.selectRows = {};
      }
    },
    showProjectDialog() {
      this.dialogProjectVisible = true;
      this.getItemList();
    },
    // 获取项目列表
    getItemList() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "application/json",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/Item?page_index=${this.tableData.current_page}&page_size=${this.tableData.per_page}&search_keyword=${this.search_keyword}&search_status=1`,
      })
        .then((res) => {
          this.tableData = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    selectRow(selection, val) {
      // 接收两个参数，selection为所有选中数据的集合，val为当前选择的数据

      // 先将所有选中的数据变为未选中状态
      this.$refs.treeTable.clearSelection();
      // 如果选择完当前数据后selection为空数组，说明现在是取消勾选，无选中数据
      if (selection.length == 0) {
        this.selectedRow = []; // 清空data中绑定的selectedRow
        return;
      }
      // 切换最近一次选择数据的状态
      this.$refs.treeTable.toggleRowSelection(val, true);
      // 更新data中的selectedRow
      this.selectedRow = val;
    },
    confirmCommidity(v) {
      this.commidityList = [];
      this.selectCommdityDialogVisible = false;
      this.commidityList.push(v);
      this.basicInfoForm.goods_uuid = v.uuid;
    },
    confirmSeckill (v) {
      this.seckillList = []
      this.seckillList.push(v);
      this.basicInfoForm.miaosha_uuid = v.uuid;
      this.selectSeckillDialogVisible = false
    },
    insertPic() {
      this.imgType = "insert_image";
      this.dialogVisible = true;
    },
    getUserGroup() {
      this.axios({
        method: "get", //请求方法
        headers: {
          "Content-Type": "text/html;charset=UTF-8",
          "X-Access-Token": this.storage.get("__token"),
          Authorization: `Bearer ${this.storage.get("__token")}`,
        },
        url: ` https://time.api.boyalife.cn/v1/cms/UserGroupSelect`,
      })
        .then((res) => {
          this.userGroupList = res.data.result;
        })
        .catch((err) => {
          this.$message.error(err.response.data.error.message);
        });
    },
    // 选择banner
    chooseBanner() {
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择封面
    confirmImageDialog(item) {
      this.basicInfoForm.image = item[0].url;
      this.dialogVisible = false;
    },
    chooseVideo() {
      this.imgType = "chooseVideo";
      this.dialogVideoVisible = true;
      this.title = "选择视频";
    },
    insertVideoHandler(url) {
      this.dialogVideoVisible = false;
      this.basicInfoForm.movie = url;
    },
    // 提交表单
    submitForm(form) {
      if (this.basicInfoForm.url_type == 2) {
        this.basicInfoForm.text = this.$refs.editors.html;
        if (
          this.basicInfoForm.text == "" ||
          this.basicInfoForm.text == "<p><br></p>"
        ) {
          this.$message.warning("富文本不能为空！");
          return false;
        }
      }
      if (
        this.basicInfoForm.url_type == 4 &&
        this.basicInfoForm.movie == null
      ) {
        this.$message.warning("请选择视频");
        return false;
      }
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.basicInfoForm.shelf_type == 1) {
            this.basicInfoForm.shelf_time = "";
          }
          if (
            this.basicInfoForm.url_type == 5 &&
            this.commidityList.length == 0
          ) {
            this.$message.warning("请选择商品");
            return false;
          }
          if (
            this.basicInfoForm.url_type ==8 &&
            this.seckillList.length == 0
          ) {
            this.$message.warning("请选择秒杀活动");
            return false;
          }
          if (
            this.basicInfoForm.url_type == 6 &&
            this.projectList.length == 0
          ) {
            this.$message.warning("请选择项目");
            return false;
          }
          if (Object.keys(this.$route.query).length !== 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Banner/${this.basicInfoForm.uuid}`,
            })
              .then((res) => {
                this.$message.success("编辑成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: ` https://time.api.boyalife.cn/v1/cms/Banner`,
            })
              .then((res) => {
                this.$message.success("新增成功！");
                this.$router.go(-1);
              })
              .catch((err) => {
                this.$message.success(err);
              });
          }
        } else {
          return false;
        }
      });
    },
    handleSizeChange(v) {
      this.tableData.per_page = v;
      this.getItemList();
    },
    handleCurrentChange(v) {
      this.tableData.current_page = v;
      this.getItemList();
    },
  },
};
</script>
<style scoped>
.addBanner {
  margin-bottom: 80px;
}
.addBanner .el-form .el-input {
  width: 300px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 16px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.insertImg {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 18px;
  top: 0;
  z-index: 2;
  cursor: pointer;
}
.insertVideo {
  width: 52px;
  height: 42px;
  position: absolute;
  left: 18px;
  top: 39px;
  z-index: 2;
  cursor: pointer;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
.upload-demo{
  border: 1px dashed #409eff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}
</style>
